import { useGlobalStore } from "@/stores/globalStore";
import { useSessionStore } from "@/stores/sessionStore";

function consoleMsg(type, msg, style) {
  console.log(`%c ${type}: ${msg} `, style);
}

export const consoleEr = (msg) => consoleMsg("Error", msg, "background: red;");
export const consoleWa = (msg) =>
  consoleMsg("Warning", msg, "background: orange;");
export const consoleSu = (msg) =>
  consoleMsg("Success", msg, "background: green;");

export function useDebouncedRef(value, delay = 700) {
  let timeout;
  return customRef((track, trigger) => {
    return {
      get() {
        track();
        return value;
      },
      set(newValue) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          value = newValue;
          trigger();
        }, delay);
      },
    };
  });
}

export function fullCopy(item) {
  return JSON.parse(JSON.stringify(item));
}

export async function fullAsyncCopy(item) {
  return new Promise((resolve) => {
    resolve(JSON.parse(JSON.stringify(item)));
  })
}

// Copia las propiedades de un objeto
export function fullCopyProps(item, model){
  for (const [key, value] of Object.entries(model)) {
    item[key] = value;
  }
  return item;
}

// Eliminar propiedades existentes dentro de un objeto
export function removeProps(item){
  for (const prop in item) {
    if (Object.prototype.hasOwnProperty.call(item, prop)) {
      delete item[prop];
    }
  }
  return item;
}

export function isObject(obj) {
  return obj !== null && typeof obj === "object" && !isArray(obj);
}

export function isArray(value) {
  return Array.isArray(value);
}

export function isString(value) {
  return typeof value === 'string';
}

export function isNumber(value) {
  return !isNaN(parseFloat(value)) && isFinite(value);
}

export function isEmpty(value){
  if (value == null) { // Maneja null o undefined
    return true;
  }

  if(isObject(value)){
    return Object.keys(value).length === 0;
  }

  if(isArray(value) || isString(value)){
    return value.length === 0;
  }
  return false;
}

export function isNotEmpty(value){
  if(isObject(value)){
    return Object.keys(value).length > 0;
  }

  if(isArray(value) || isString(value)){
    return value.length > 0;
  }
  return false;
}

export function capitalize(str) {
  if (!str) return;
  return str[0].toUpperCase() + str.slice(1);
}

export function capitalizeAllWords(str) {
  return str.replace(/\b\w+/g, (word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const restOfWord = word.slice(1).toLowerCase();
    return firstLetter + restOfWord;
  });
}

export function flattenizeRecords(items) {
  function flattenize(obj) {
    const res = {};
    for (const [k, v] of Object.entries(obj)) {
      if (isObject(v)) {
        for (const [innerK, innerV] of Object.entries(v)) {
          res[k] = v;
          if (!Object.keys(obj).includes(innerK) && !Array.isArray(innerV)) {
            res[innerK] = innerV;
          }
          if (isObject(innerV)) {
            for (const [subInnerK, subInnerV] of Object.entries(innerV)) {
              if (isObject(subInnerV)) {
                if (
                  !Object.keys(obj).includes(subInnerK) &&
                  !Array.isArray(subInnerV)
                ) {
                  res[subInnerK] = subInnerV;
                }
              }
            }
          }
        }
      } else {
        res[k] = v;
      }
    }
    return res;
  }
  if (Array.isArray(items)) return items.map((i) => flattenize(i));
  return flattenize(items);
}

export function rankear(items, isRanking, rankingBy){
  if (!isRanking || items.length === 0) {
    return items;
  }
  const groupedItems = {};

  items.forEach((item) => {
    const key = item[rankingBy];

    if (key !== "desc") {
      if (!groupedItems[key]) {
        groupedItems[key] = {
          ...item,
          totalQuantity: +item.quantity,
          discount: +item.quantity * +item.discount,
          total_cost: +item.quantity * (+item.cost_net + +item.cost_untaxed),
          totalNetSales: +item.quantity * (+item.price - +item.discount),
          totalProfit: +item.quantity * (+item.price - +item.discount - +item.cost_net - +item.cost_untaxed),
          totalSales: +item.quantity * +item.price,
        };
      } else {
        groupedItems[key].totalQuantity += +item.quantity;
        groupedItems[key].total_cost += +item.quantity * (+item.cost_net + +item.cost_untaxed);
        groupedItems[key].totalNetSales += +item.quantity * (+item.price - +item.discount);
        groupedItems[key].totalProfit += +item.quantity * (+item.price - +item.discount - +item.cost_net - +item.cost_untaxed);
        groupedItems[key].discount += +item.quantity * +item.discount;
        groupedItems[key].totalSales += +item.quantity * +item.price;
      }
    }
  });

  const result = Object.values(groupedItems);
  return result;
}

export function showAlertMsg(msg = "Accion procesada con exito") {
  alertMessage.value = msg;
  alertMessageColor.value = "success";
  dialogAlertMessage.value = true;
  useGlobalStore().desactiveLoader();
  setTimeout(function () {
    alertMessage.value = {};
    alertMessageColor.value = "";
    dialogAlertMessage.value = false;
  }, 4000);
}

export function showWarning(e, time = 3000) {
  alertMessageColor.value = "orange-darken-4";
  alertMessage.value = e ?? "Hubo un error";
  dialogAlertMessage.value = true;
  useGlobalStore().desactiveLoader();
  setTimeout(function () {
    alertMessage.value = {};
    alertMessageColor.value = "";
    dialogAlertMessage.value = false;
  }, time);
}

export function showBuildError(msg) {
  alertMessage.value = msg;
  alertMessageColor.value = "error";
  dialogAlertMessage.value = true;
  useGlobalStore().desactiveLoader();
  setTimeout(function () {
    alertMessage.value = {};
    alertMessageColor.value = "";
    dialogAlertMessage.value = false;
  }, 4000);
}

export async function showError(e, time = 5000) {
  if(e?.response?.status === 401){
    useGlobalStore().buildLoader = false;
    await useSessionStore().clearStore();
    throw new Error("Sesión inválida.");
  }

  alertMessageColor.value = "error";
  alertMessage.value = e.response?.data?.errors ??
  e.response?.data?.message ?? e.message ?? "Hubo un error";
  dialogAlertMessage.value = true;
  useGlobalStore().desactiveLoader();
  setTimeout(function () {
    alertMessage.value = {};
    alertMessageColor.value = "";
    dialogAlertMessage.value = false;
  }, time);
}

export function showSistemMsg(msg) {
  const debug = import.meta.env.debug;
  if(useSessionStore().isRoot && debug){
    console.log(msg);
  }
}

export function showSistemError(e) {
  if(useSessionStore().isRoot){
    console.error(e.msg);
    console.log(e.event);
  }
}

export function getNowDate() {
  const ahora = new Date();

  const year = ahora.getFullYear();
  const month = (ahora.getMonth() + 1).toString().padStart(2, '0'); // Los meses son de 0 a 11, así que sumamos 1
  const day = ahora.getDate().toString().padStart(2, '0');

  // Construir la cadena de fecha en el formato YYYY-MM-DD
  const fechaFormateada = `${year}-${month}-${day}`;

  return fechaFormateada;
}

export function getNowDateTime() {
  return dateStringTo(
    new Date().toLocaleString("es-MX", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
  );
}

export function getNowTime() {
  return new Date().toLocaleTimeString("es-MX", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
}

export function convertDateTimeToMsTime(value){
  let time = value.toLocaleTimeString("es-MX", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  let parts = time.split(':');

  let hr = parts[0] * 60 * 60 * 1000;
  let min = parts[1] * 60 * 1000;
  let sec = parts[2] * 1000;
  let res = +hr + +min + +sec;
  return res;
}

export function convertTimeToMs(value){
  if(!value) return 0;
  let parts = value.split(':');

  let hr = parts[0] * 60 * 60 * 1000;
  let min = parts[1] * 60 * 1000;
  let sec = parts[2] * 1000;
  let res = +hr + +min + +sec;
  return res;
}

export function convertMsToTime(){

}

export function convertMsToDateTime(){
  
}

export function dateDiffInDays(fecha1, fecha2) {
  // Convertir las cadenas a objetos Date
  const fecha1Obj = new Date(fecha1);
  const fecha2Obj = new Date(fecha2);

  // Calcular la diferencia en milisegundos
  const diferenciaEnMilisegundos = fecha1Obj - fecha2Obj;

  // Calcular la diferencia en días
  const diferenciaEnDias = diferenciaEnMilisegundos / (1000 * 60 * 60 * 24);

  return diferenciaEnDias;
}

// works for any type of url, (http url, dataURL, blobURL, etc...)
export async function urltoFile(url, name, mimeType){
  if (url.startsWith('data:')) {
      var arr = url.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[arr.length - 1]), 
          n = bstr.length, 
          u8arr = new Uint8Array(n);
      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], name, {type:mime || mimeType});
      return Promise.resolve(file);
  }
  return fetch(url)
      .then(res => res.arrayBuffer())
      .then(buf => new File([buf], name,{type:mimeType}));
}

export function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

/////////////////////////////////////////////////////////
//// COMIENZO FUNCION DE REDIMENSION DE IMAGENES ///////
///////////////////////////////////////////////////////
export async function resizeImage(value, pixels = 200, querySelectorTag = 'image'){
  const resizeWidth = pixels; //pixels to resize

  return new Promise((resolve) => {
    resolve(readerImage(value, querySelectorTag, resizeWidth));
  });
}

async function readerImage(value, querySelectorTag, resizeWidth){
    var reader = new FileReader(); //create a FileReader

    reader.readAsDataURL(value); //image turned to base64-encoded Data URI.
    reader.name = value.name;//get the image's name
    reader.size = value.size; //get the image's size

    return new Promise((resolve) => {
      reader.onload = (event) => {
        resolve(resizeFinish(event, querySelectorTag, resizeWidth));
      }
    });
}

async function resizeFinish(event, querySelectorTag, resizeWidth){
  var img = new Image();//create a image
  img.src = event.target.result;//result is base64-encoded Data URI
  img.name = event.target.name;//set name (optional)
  img.size = event.target.size;//set size (optional)

  return new Promise((resolve) => {
    img.onload = (el) => {
      var elem = document.createElement('canvas');//create a canvas

      //scale the image width and keep aspect ratio
      const scaleFactor = resizeWidth / el.target.width;
      elem.width = resizeWidth;
      elem.height = el.target.height * scaleFactor;

      //draw in canvas
      var ctx = elem.getContext('2d');
      ctx.drawImage(el.target, 0, 0, elem.width, elem.height);

      //get the base64-encoded Data URI from the resize image
      const result = ctx.canvas.toDataURL('image/png', 1);

      document.querySelector(`#${querySelectorTag}`).src = result; //assign it to thumb src

      resolve(result); // return result
    }
  });
}
/////////////////////////////////////////////////////////
/////// FIN FUNCION DE REDIMENSION DE IMAGENES /////////
///////////////////////////////////////////////////////

export async function buildSyncToAsync(stepFunctions, arrayOfObjects = []){
  const results = [];

  // Función recursiva para ejecutar las funciones secuencialmente
  async function executeNext(index) {
    if (index < stepFunctions.length) {
      const result = await stepFunctions[index](arrayOfObjects[index]);
      results.push(result);
      await executeNext(index + 1);
    }
  }

  await executeNext(0); // Comienza la ejecución desde el índice 0
  return results; // Devuelve un array con los resultados en el orden correcto
}

export async function delay(ms) {
  await new Promise(resolve => setTimeout(resolve, ms)); // Espera un poco antes de verificar de nuevo
}

export function delayWithCallback(ms, callback = null) {
  return new Promise(resolve =>
  setTimeout(async () => {
    if (callback && typeof callback === 'function') {
      await callback()
    }
      resolve()
    }, ms)
  )
}

export function pluralize(word, preserveCase = true) {
  const exceptions = {
    'man': 'men',
    'woman': 'women',
    'child': 'children',
    'tooth': 'teeth',
    'foot': 'feet',
    'person': 'people',
    'mouse': 'mice',
    'goose': 'geese',
    'cactus': 'cacti',
    'focus': 'foci',
    'radius': 'radii',
    'analysis': 'analyses',
    'thesis': 'theses',
  };

  // Manejo de excepciones especiales
  if (exceptions[word.toLowerCase()]) {
    return preserveCase ? exceptions[word.toLowerCase()] : exceptions[word.toLowerCase()].toLowerCase();
  }

  // Reglas generales
  const rules = [
    [/(matr|vert|ind)(ix|ex)$/i, '$1ices'], // index -> indices, matrix -> matrices
    [/([m|l])ouse$/i, '$1ice'],            // mouse -> mice
    [/(x|ch|ss|sh)$/i, '$1es'],            // box -> boxes, church -> churches
    [/([^aeiouy]|qu)y$/i, '$1ies'],        // category -> categories, city -> cities
    [/(hive)$/i, '$1s'],                   // hive -> hives
    [/([^f])fe$/i, '$1ves'],               // knife -> knives
    [/sis$/i, 'ses'],                      // thesis -> theses
    [/([ti])um$/i, '$1a'],                 // datum -> data
    [/(p)erson$/i, '$1eople'],             // person -> people
    [/([^aeiou])o$/i, '$1oes'],            // hero -> heroes, potato -> potatoes
    [/s$/i, 's'],                          // palabras que ya terminan en 's' permanecen iguales
    [/$/, 's'],                            // regla general (añadir 's' al final)
  ];

  for (const [pattern, replacement] of rules) {
    if (pattern.test(word)) {
      const pluralized = word.replace(pattern, replacement);
      return preserveCase ? matchCase(word, pluralized) : pluralized.toLowerCase();
    }
  }

  return preserveCase ? word : word.toLowerCase();
}

// Función para mantener el caso original
function matchCase(original, modified) {
  return original === original.toLowerCase() ? modified.toLowerCase() :
    original === original.toUpperCase() ? modified.toUpperCase() :
    original === original.charAt(0).toUpperCase() + original.slice(1).toLowerCase() ? modified.charAt(0).toUpperCase() + modified.slice(1).toLowerCase() :
    modified;
}

export function singularize(word, preserveCase = true) {
  const exceptions = {
    'men': 'man',
    'women': 'woman',
    'children': 'child',
    'teeth': 'tooth',
    'feet': 'foot',
    'people': 'person',
    'mice': 'mouse',
    'geese': 'goose',
    'cacti': 'cactus',
    'foci': 'focus',
    'radii': 'radius',
    'analyses': 'analysis',
    'theses': 'thesis',
  };

  // Manejo de excepciones especiales
  if (exceptions[word.toLowerCase()]) {
    return preserveCase ? exceptions[word.toLowerCase()] : exceptions[word.toLowerCase()].toLowerCase();
  }

  // Reglas generales para singularizar
  const rules = [
    [/s$/, ''], // Singular simple (eliminar 's' al final)
    [/es$/, 'is'], // Cambiar 'es' a 'is' (axes, tests)
    [/i$/, 'us'], // Cambiar 'i' a 'us' (octopi, viri)
    [/i$/, 'us'], // Cambiar 'i' a 'us' en palabras ya en singular (octopus, virus)
    [/a$/, 'um'], // Cambiar 'a' a 'um' (data, medium)
    [/people$/, 'person'], // Cambiar 'people' a 'person' (people)
    [/ives$/, 'ife'], // Cambiar 'ives' a 'ife' (lives, knives)
    [/ies$/, 'y'], // Cambiar 'ies' a 'y' (cities, countries)
    [/([ti])a$/, '$1um'], // Cambiar 'a' a 'um' (data, medium)
  ];

  for (const [pattern, replacement] of rules) {
    if (pattern.test(word)) {
      const singularized = word.replace(pattern, replacement);
      return preserveCase ? matchCase(word, singularized) : singularized.toLowerCase();
    }
  }

  return preserveCase ? word : word.toLowerCase();
}

export function sortArrayBy(array, key = "order", order = "asc") {
  if(!isArray(array)) return;
  return [...array].sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA === null && valueB === null) return 0;
    if (valueA === null) return 1;
    if (valueB === null) return -1;

    let result;

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      result = valueA - valueB;
    } else if (typeof valueA === 'string' && typeof valueB === 'string') {
      const numA = parseFloat(valueA);
      const numB = parseFloat(valueB);

      if (!isNaN(numA) && !isNaN(numB)) {
        const dateA = convertInDate(valueA);
        const dateB = convertInDate(valueB);

        if (dateA === null || dateB === null) {
          result = numA - numB;
        } else {
          result = dateA - dateB;
        }
      } else {
        result = valueA.localeCompare(valueB, undefined, { numeric: true });
      }
    } else if (valueA instanceof Date && valueB instanceof Date) {
      result = valueA - valueB;
    } else {
      result = 0; // Ajusta esto según tus necesidades específicas
    }

    // Aplicar dirección del orden
    return order.toLowerCase() === 'desc' ? -result : result;
  });
}

// Función para convertir el formato específico de fecha a objeto Date
function convertInDate(value){
  // Expresión regular para verificar el formato de fecha
  const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}Z$/;

  if (!dateFormatRegex.test(value)) {
    return null; // Retorna null si el formato no es válido
  }

  const parts = value.split(/[-T:.Z]/);
  return new Date(Date.UTC(
    parseInt(parts[0], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[2], 10),
    parseInt(parts[3], 10),
    parseInt(parts[4], 10),
    parseInt(parts[5], 10)
  ));
}

export function sortObjectBy(obj, key = "order") {
  if(!obj) return {};
  const sortedEntries = Object.entries(obj)
    .filter(([_, value]) => value && value[key] !== undefined)
    .sort(([_, a], [__, b]) => {
      const valueA = a[key] !== null ? a[key] : Infinity;
      const valueB = b[key] !== null ? b[key] : Infinity;
      return valueA - valueB;
    });

  const sortedObject = Object.fromEntries(sortedEntries);
  return sortedObject;
}

export function compareObjects(obj1, obj2) {
  // Verificar si son el mismo tipo de dato y si son objetos
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return obj1 !== obj2; // Comparar si los valores son diferentes
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Verificar si el número de claves es diferente
  if (keys1.length !== keys2.length) {
    return true;
  }

  // Comparar cada propiedad de los objetos
  for (const key of keys1) {
    if (!compareObjects(obj1[key], obj2[key])) {
      return true; // Si hay alguna diferencia, retornar true
    }
  }

  // No se encontraron diferencias
  return false;
}

export function findDifferences(obj1, obj2) {
  const diffs = {};

  function compare(obj1, obj2, path) {
    for (const key in obj1) {
      const newPath = (path ? path + '.' : '') + key;
      if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        compare(obj1[key], obj2[key], newPath);
      } else if (obj1[key] !== obj2[key]) {
        diffs[newPath] = { oldValue: obj2[key], newValue: obj1[key] };
      }
    }
  }

  compare(obj1, obj2, '');

  return diffs;
}