import { defineStore } from "pinia";
import { useSessionStore } from "@/stores/sessionStore";
import { usePosStore } from "./posStore";

export const useGeneralStore = defineStore("general", {
  state: () => ({
    modules: [],
    navigationStructure: [],
    sidebarShortcuts: [],
    desktopShortcuts: [],

    date: [
      dateStringTo(
        new Date().toLocaleString("es-MX", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        "start"
      ),

      dateStringTo(
        new Date().toLocaleString("es-MX", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        }),
        "end"
      ),
    ],
    dateTwo: [],

    //GPS
    gpsCount: 0,
    gpsMsg: null,
    geolocalization: {
      coords: {
        accuracy: 101,
        altitude: null,
        altitudeAccuracy: null,
        heading: null,
        latitude: 0,
        longitude: 0,
        speed: null,
      },
      timestamp: 0,
    },

    actualModule: "",
    receiptToProcess: {},
  }),
  // persist: { paths: ["receiptToProcess"] },
  persist: {excludes: ["date"]},
  // persist: true,

  getters: {
    accountPaymentStatus() {
      return useSessionStore().account?.status?.status ?? true;
    },

    hasModuleCashRegister() {
      return this.modules.some((i) => i.name.includes("PointOfSale"));
    },

    hasModuleWarehouse() {
      return this.modules.some((i) => i.name.includes("Warehouse"));
    },

    hasModuleProduct() {
      return this.modules.some((i) => i.name.includes("Product"));
    },

    hasModulePendingSaleOrder() {
      return this.modules.some((i) => i.name.includes("PendingSaleOrder"));
    },

    hasGenericProducts() {
      return usePosStore().selectedCompany.config?.hasGenericProducts;
    },

    hasStockControl(){
      return this.hasModuleProduct && usePosStore().selectedCompany.config.hasStockControl;
    },

    canSellWithoutStock() {
      return usePosStore().selectedCompany.config?.canSellWithoutStock;
    },

    availableRoutes(){
      return this.modules.map((i) => i.name);
    },

    async isModulesLoaded() {
      while (!this.modules.length) {
        // Run every half second until we have a profile.
        await new Promise((resolve) => setTimeout(resolve, 500));
      }
    },
  },

  actions: {
    gpsLocationLink(position) {
      return (
        "https://www.openstreetmap.org/#map=18/" +
        position.coords.latitude +
        "/" +
        position.coords.longitude
      );
    },
  },
});

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useGeneralStore, import.meta.hot));
