import { defineStore } from "pinia";

export const useTableStore = (moduleId = "default") =>
  defineStore(`table/${moduleId}`, {
    state: () => ({
      route: '',

      headers: [],
      formFields: [],
      configCustom: {},
      records: [],
      searchVmodel: "",
      searchFocused: false,
      selectedItem: {},
      selectedItems: [],
      itemsOneToMany: [],
      tableTitleOneToMany: '',
      relationOneToMany: '',
      itemText: '',
      newItem: {},
      sortedBy: "id",
      sortOrder: "asc",

      isRanking: false,
      rankingBy: null,

      oneToOne: {},
      oneToMany: {},
      parentMenus: [],

      date:[
        dateStringTo(
          new Date().toLocaleString("es-MX", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }),
          "start"
        ),
      
        dateStringTo(
          new Date().toLocaleString("es-MX", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          }),
          "end"
        ),
      ],

      //Paginacion
      actualPage: 1,
      itemsPerPage: 25,

      //Filtro
      showFilter: false,
      filters: [],

      //Form
      formAction: null,
      formStatus: false,

      //Deleted items table
      dialogTableDeleted: false,
    }),

    getters: {
      tableRecords() {
        if (!this.selectedFilters.length) return this.records;

        if (this.selectedFilters.length)
          return flattenizeRecords(this.records).filter((item) =>
            this.selectedFilters.every((filter) =>
              filter.items.some((i) => item[filter.value] === i.id)
            )
          );
      },

      filteredRecords() {
        if (!this.searchVmodel)
        return sortArrayBy(rankear(this.tableRecords, this.isRanking, this.rankingBy), this.sortedBy, this.sortOrder);

        const searchableColumns = this.headers.filter(
          (column) => column.searchable
        );

        return sortArrayBy(rankear(this.tableRecords, this.isRanking, this.rankingBy)
          .filter((item) => {
            return searchableColumns.some((column) =>
              item[column.value]
                ? item[column.value]
                    .toString()
                    .toLowerCase()
                    .includes(this.searchVmodel.toLowerCase())
                : ""
            );
          }), this.sortedBy, this.sortOrder);
      },

      paginatedRecords() {
        if (Number.isInteger(this.itemsPerPage)) {
          return this.filteredRecords.slice(this.ini, this.fin);
        }
        return this.filteredRecords.slice(0, this.filteredRecords.length);
      },

      allSelected() {
        return  this.filteredRecords.length === this.selectedItems.length;
      },

      someSelected() {
        return this.selectedItems.length && !this.allSelected;
      },

      icon() {
        if (this.allSelected) return "$mdiCheckboxMarked";
        if (this.someSelected) return "$mdiMinusBoxOutline";
        return "$mdiCheckboxBlankOutline";
      },

      selectedFilters() {
        return this.filters.filter((i) => i.items.length);
      },

      ini() {
        return this.actualPage * this.itemsPerPage - this.itemsPerPage;
      },

      fin() {
        return this.actualPage * this.itemsPerPage;
      },

      showColumns() {
        return this.headers.filter((i) => i.display);
      },

      hasRecoverItems() {
        return this.configCustom?.tableHeader?.hasRecoverItems;
      },

      showCheckbox() {
        return this.configCustom?.tableHeader?.hasCheckBox;
      },
    },

    actions: {
      sortFunction(item) {
        this.sortedBy = item.value;
        if (this.sortOrder === "asc") {
          this.sortOrder = "desc";
        } else {
          this.sortOrder = "asc";
        }
      },

      sortTableRecords(a, b) {
        if (typeof a[this.sortedBy] === "number") {
          return this.sortOrder === "asc"
            ? a[this.sortedBy] - b[this.sortedBy]
            : b[this.sortedBy] - a[this.sortedBy];
        }
        if (typeof a[this.sortedBy] === "string") {
          if (this.sortOrder === "asc") {
            let bValue = b[this.sortedBy] ?? "";
            let aValue = a[this.sortedBy] ?? "";
            return bValue.localeCompare(aValue);
          } else {
            let bValue = b[this.sortedBy] ?? "";
            let aValue = a[this.sortedBy] ?? "";
            return aValue.localeCompare(bValue);
          }
        }
      },

      switchSelect() {
        if (this.allSelected) {
          this.selectedItems = [];
        } else {
          this.selectedItems = [ ...this.filteredRecords];
        }
      },

      editItem(item, index) {
        this.selectedItems = [];
        const res = this.paginatedRecords.find(i => i.id === item.id);
        this.selectedItem = { ...res};
        this.formAction = "Editar";
        this.formStatus = true;
      },

      isSelected(item) {
        return this.selectedItems.includes(item);
      },
  
      updateSelectedItems(item) {
        if (this.selectedItems.includes(item)) {
          this.selectedItems = this.selectedItems.filter(selectedItem => selectedItem !== item);
        } else {
          this.selectedItems.push(item);
        }
    },

  }
  })();

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTableStore, import.meta.hot));
